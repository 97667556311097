import React from "react";

const Resume = ({ data }) => {
  if (data) {
    var skillmessage = data.skillmessage;
    // var toolmessage = data.toolmessage;
    var education = data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });
    var education2 = data.education2.map(function (education2) {
      return (
        <div key={education2.school}>
          <h3>{education2.school}</h3>
          <p className="info">
            {education2.degree} <span>&bull;</span>
            <em className="date">{education2.graduated}</em>
          </p>
          <p>{education2.description}</p>
        </div>
      );
    });
    var experience = data.experience.map(function (experience) {
      return (
        <div key={experience.company}>
          <h3>{experience.company}</h3>
          <p className="info">
            {experience.title}
            <span>&bull;</span> <em className="date">{experience.years}</em>
          </p>
          <p>{experience.description}</p>
        </div>
      );
    });
    var other_experience = data.other_experience.map(function (other_experience) {
      return (
        <div key={other_experience.company}>
          <h3>{other_experience.company}</h3>
          <p className="info">
            {other_experience.title}
            <span>&bull;</span> <em className="date">{other_experience.years}</em>
          </p>
          <p>{other_experience.description}</p>
        </div>
      );
    });
    var skills = data.skills.map(function (skills) {
      var className = "bar-expand " + skills.name.toLowerCase();
      return (
        <li key={skills.name}>
          <span style={{ width: skills.level }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });
    // var tools = data.tools.map(function (tools) {
    //   return (
    //     <div key={tools.name}>
    //       <h3>{other_experience.company}</h3>
    //       <p className="info">
    //         {other_experience.image}
    //         <span>&bull;</span> <em className="date">{other_experience.years}</em>
    //       </p>
    //       <p>{other_experience.description}</p>
    //     </div>
    //   );
    // });
  }
  
  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education2}</div>
          </div>
        </div>
      </div>

      <div className="row experience">
        <div className="three columns header-col">
          <h1>
            <span>Related Experience</span>
          </h1>
        </div>

        <div className="nine columns main-col">{experience}</div>

        <div className="three columns header-col">
          <h1>
            <span>Other Experience</span>
          </h1>
        </div>

        <div className="nine columns main-col">{other_experience}</div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <p>{skillmessage}</p>

          <div className="bars">
            <ul className="skills">{skills}</ul>
          </div>
        </div>
      </div>

      {/* <div className="row tool">
        <div className="three columns header-col">
          <h1>
            <span>Tools</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <p>{toolmessage}</p>

          <div className="bars">
            <ul className="tools">{tools}</ul>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Resume;
